import { mainStyles, mainDiv, commentUserInput, commentTextArea, commentActionDiv, publishCommentButton,
    publishCommentButtonSending, resultMessageClass, introTextP } from './contacto.module.css'
import React, { useState, useEffect } from "react"
import Footer from "../../components/Footer"
import NavBar from "../../components/NavBar"
import fbTrackUtils from "../../utils/fbTrackUtils"
import { Helmet } from "react-helmet"

const IndexPage = () => {

    const [ sendingStatus, setSendingStatus ] = React.useState(false);
    const [ resultMessage, setResultMessage ] = React.useState("");
    const [comment, setComment] = useState({
        comment: '',
        name: '',
        mail: ''
    });

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        setComment({
            ...comment,
            [name]: value,
        })
    }

    function sendMsj() {
        if (!comment.name || !comment.mail || !comment.comment) {
            return;
        }

        fbTrackUtils.trackContact();

        var commentClone = JSON.parse(JSON.stringify(comment));
        commentClone.publicationDate = (new Date()).toISOString();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(commentClone)
        };
      
        setSendingStatus(true);
        fetch(`${process.env.GATSBY_API_URL}api/contact/saveNew`, requestOptions)
            .then(resultData => {
                setSendingStatus(false);
                setComment({comment: '', name: '', mail: ''});
                setResultMessage("Mensaje enviado correctamente.");
        })
    }

  return (
    <div className={mainStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contacto - Fede Cocina</title>
          <meta name="description" content="Contacto"/>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
        </Helmet>
      <NavBar></NavBar>
      <div className={mainDiv}>
        <p className={introTextP}>¡Deja tu mensaje acá con cualquier duda, inquietud o consulta que tengas y muy brevemente voy a estar respondiéndote!</p>
        <input className={commentUserInput} name="name" value={comment.name} onChange={handleInputChange} placeholder='Nombre*' maxLength={60}></input>
        <input className={commentUserInput} name="mail" value={comment.mail} onChange={handleInputChange} placeholder='Email*' maxLength={100}></input>
        <textarea className={commentTextArea} name="comment" value={comment.comment} onChange={handleInputChange} rows="9" placeholder=""></textarea>
        <div className={commentActionDiv}>
            <button className={sendingStatus ? publishCommentButtonSending : publishCommentButton} disabled={sendingStatus} type="submit" onClick={sendMsj}>{sendingStatus ? "Enviando..." : "Enviar"}</button>
        </div>
        { resultMessage ? 
          <p className={resultMessageClass}>{resultMessage}</p>
          :
          <></>
        }
      </div>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
